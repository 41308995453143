//头部下载背景色
@mixin btn_bg {
  @if $yb-platform=='leyu' {
    background: linear-gradient(113deg, #69cfff, #127dff 99%);
  }

  @else if $yb-platform=='yb' or $yb-platform=='ky' {
    background: linear-gradient(90deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%);
  }

  @else if $yb-platform=='ayx' or $yb-platform=='hth' {
    background: linear-gradient(90deg, #dccab8 0%, #d2b496 100%);
  }

  @else {
    background: #2599F8;
  }
}

// 公告颜色
@mixin text_color {
  @if $yb-platform=='leyu' {
    color: #9aa4c2;
  }

  @else if $yb-platform=='yb' {
    color: $yb-normal-color;
  }

  @else if $yb-platform=='ky' {
    color: $yb-normal-color;
  }

  @else {
    color: #a5a9b3;
  }
}

// 掉签弹窗 不同样式
@mixin importTips_bg {
  @if $yb-platform=='leyu' {
    background-color: #52a2ff;
  }

  @else if $yb-platform=='yb' {
    background-color: $yb-gradient-color-2;
  }

  @else {
    background-color: rgb(218, 200, 181);
  }
}